<template>
    <iframe
        id="gameIframe"
        ref="gameIframe"
        :src="iframeSrc"
        allow="fullscreen;camera;xr-spatial-tracking"
        allowusermedia
        class="iframe-box"
        title="Game Server Iframe"
    />
</template>

<script>
    import taskUtil from '@/utils/task-util'
    import emitter from '@/utils/emitter'
    import statsHelper from '@/mixins/statsHelper'

    export default {
        name: 'Game',
        mixins: [statsHelper],
        props: {
            freeGame: {
                type: Object,
                required: false,
                default: null
            }
        },
        computed: {
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            mission() {
                return this.$store.getters.getMission(parseInt(this.$route.params.missionId))
            },
            scanCode() {
                return this.$store.getters.getScanCode
            },
            lang() {
                return this.$store.getters.getLanguage
            },
            iframeSrc() {
                const gameId = this.freeGame ? this.freeGame?.task?.id : this.task?.id
                if (!gameId) {
                    return null
                }
                // if (this.locationPath === this.config.host) {
                //     return `${this.config.gameServerHost}/games-bond/game?targetOrigin=${this.config.host}&lang=${this.lang}&isChallenge=false&gameId=${gameId}`
                // } else {
                //     return `${this.config.gameServerHost}/games-bond/game?targetOrigin=${this.locationPath}&apiHost=${this.config.host}&lang=${this.lang}&isChallenge=false&gameId=${gameId}`
                // }

                if (this.locationPath === this.config.host) {
                    return `${this.config.gameServerHost}/gaiming/games/${gameId}?targetOrigin=${this.config.host}&lang=${this.lang}&isChallenge=false`
                } else {
                    return `${this.config.gameServerHost}/gaiming/games/${gameId}?targetOrigin=${this.locationPath}&apiHost=${this.config.host}&lang=${this.lang}&isChallenge=false`
                }
            },

            locationPath() {
                return window.location.host.includes('localhost')
                    // ? 'http://localhost:8080'
                    ? 'https://dev-fraalliance.mpass.ltd'
                    : window.location.protocol + '//' + window.location.host
            }

        },
        created() {
            window.addEventListener('message', this.messageEventHandler)
        },
        beforeDestroy() {
            this.closeFrame()
            window.removeEventListener('message', this.messageEventHandler)
        },
        mounted() {
            const vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
            window.addEventListener('resize', () => {
                const vh = window.innerHeight * 0.01
                document.documentElement.style.setProperty('--vh', `${vh}px`)
            })

            const element = document.getElementById('gameIframe')
            element.scrollIntoView({ block: 'end', inline: 'nearest' })
        },
        methods: {
            messageEventHandler(event) {
                if (!this.config.gameServerHost) {
                    return
                }
                const json = event.data
                if (!this.task) {
                    return
                }

                if (json.action === 'finish') {
                    emitter.emit('killTimer', { taskId: this.task.id })
                    if (this.freeGame) {
                        this.storeFreeTaskInfo(this.freeGame, json.score)
                        if (this.scanCode) {
                            this.scanQrSpot(this.scanCode, 'QR_SCAN', 'FREE_GAME_PLAYED')
                        }
                        return
                    }
                    if (!this.task.completed) {
                        taskUtil.updateTask(this.mission, this.task, json.score, true, this.$router)
                    } else {
                        emitter.emit('openTaskEndModal', {
                            task: this.task,
                            pointsWon: 0,
                            isLastTask: false,
                            missionId: this.mission.id,
                            isPlayAgain: true
                        })
                        this.$router.push(`/missions/${this.mission.id}`)
                    }
                } else if (json.action === 'start') {
                    emitter.emit('startTimer', { taskId: this.task.id, duration: this.task.duration, isTonyGame: true })
                } else if (json.action === 'close') {
                    this.closeFrame()
                }
            },
            closeFrame() {
                const frame = document.getElementById('gameIframe')
                if (frame) {
                    frame.remove()
                }
            },
            storeFreeTaskInfo(freeGameDTO, score) {
                emitter.emit('openFreeGameModal', { freeGameDTO, score })
                this.$store.commit('setFreeTaskInfo', { freeGameDTO, score })
            }
        }
    }
</script>
<style scoped>
.iframe-box {
    border: none;
    height: 100vh;
    width: 100%;
}
</style>
